<!--
 * @Author: lxiang
 * @Date: 2023-04-23 08:47:02
 * @LastEditors: lxiang
 * @LastEditTime: 2023-07-17 11:06:43
 * @description: webGL--孔明灯
 * @FilePath: \sea_mobile\src\views\threejs\demo\Be.vue
-->
<template>
  <div class="info">
    <Header :title="title" transparent :nav="true" :defaultNav="true" />
    <div class="box" ref="box"></div>
  </div>
</template>

<script setup>
import Header from "@/components/header/Header.vue";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import gsap from "gsap"; // 动画库
// 导入threejs
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import vertexShader from "@/views/threejs/demo/shaders/flylight/vertex.glsl";
import fragmentShader from "@/views/threejs/demo/shaders/flylight/fragment.glsl";

const route = useRoute();
const title = route?.query?.title;
const box = ref(null);

const draw = () => {
  // 初始化场景
  const scene = new THREE.Scene();

  // 创建透视相机
  const camera = new THREE.PerspectiveCamera(
    90,
    window.innerHeight / window.innerHeight,
    0.1,
    1000
  );
  // 设置相机位置
  // object3d具有position，属性是1个3维的向量
  camera.position.set(0, 0, 2);
  // 更新摄像头
  camera.aspect = window.innerWidth / window.innerHeight;
  //   更新摄像机的投影矩阵
  camera.updateProjectionMatrix();
  scene.add(camera);

  // 加入辅助轴，帮助我们查看3维坐标轴
  const axesHelper = new THREE.AxesHelper(5);
  scene.add(axesHelper);

  // 创建纹理加载器对象
  const rgbeLoader = new RGBELoader();
  rgbeLoader.loadAsync("./texture/2k.hdr").then((texture) => {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.background = texture;
    scene.environment = texture;
  });

  // 创建着色器材质;
  const shaderMaterial = new THREE.ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    uniforms: {},
    side: THREE.DoubleSide,
    //   transparent: true,
  });

  // 初始化渲染器
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.2;

  const gltfLoader = new GLTFLoader();
  let LightBox = null;
  gltfLoader.load("./threejsModules/flyLight.glb", (gltf) => {
    LightBox = gltf.scene.children[1];
    LightBox.material = shaderMaterial;

    for (let i = 0; i < 150; i++) {
      let flyLight = gltf.scene.clone(true);
      let x = (Math.random() - 0.5) * 300;
      let z = (Math.random() - 0.5) * 300;
      let y = Math.random() * 60 + 25;
      flyLight.position.set(x, y, z);
      gsap.to(flyLight.rotation, {
        y: 2 * Math.PI,
        duration: 10 + Math.random() * 30,
        repeat: -1,
      });
      gsap.to(flyLight.position, {
        x: "+=" + Math.random() * 5,
        y: "+=" + Math.random() * 20,
        yoyo: true,
        duration: 5 + Math.random() * 10,
        repeat: -1,
      });
      scene.add(flyLight);
    }
  });

  // 设置渲染尺寸大小
  renderer.setSize(box.value.offsetWidth, box.value.offsetHeight);

  // 监听屏幕大小改变的变化，设置渲染的尺寸
  window.addEventListener("resize", () => {
    //   console.log("resize");
    // 更新摄像头
    camera.aspect = window.innerWidth / window.innerHeight;
    //   更新摄像机的投影矩阵
    camera.updateProjectionMatrix();

    //   更新渲染器
    renderer.setSize(box.value.offsetWidth, box.value.offsetHeight);
    //   设置渲染器的像素比例
    renderer.setPixelRatio(window.devicePixelRatio);
  });

  // 将渲染器添加到body
  box.value.appendChild(renderer.domElement);

  // 初始化控制器
  const controls = new OrbitControls(camera, renderer.domElement);
  // 设置控制器阻尼
  controls.enableDamping = true;
  // 设置自动旋转
  controls.autoRotate = true;
  controls.autoRotateSpeed = 0.1;
  controls.maxPolarAngle = (Math.PI / 3) * 2;
  controls.minPolarAngle = (Math.PI / 3) * 2;

  function animate() {
    controls.update();
    requestAnimationFrame(animate);
    // 使用渲染器渲染相机看这个场景的内容渲染出来
    renderer.render(scene, camera);
  }

  animate();
};
onMounted(() => {
  draw();
});
</script>
<style lang="less" scoped>
.info {
  padding-top: var(--nav-bar-height);
  height: 100%;
  .box {
    height: 100%;
  }
}
</style>
